import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";


ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChart = ({data: _data=[]}) => {
 
  const data = {
    labels: _data.map((item) =>
      new Date(item.date).toLocaleDateString("en-US", { month: "short", day: "numeric" })
    ),
    datasets: [
      {
        label: "Counts",
        data: _data.map((item) => item.count),
        backgroundColor: "#2181FA",
        borderRadius: 6,
      },
    ],
  };


  const options = {
    responsive: true,
    plugins: {
      legend: { display: false },
      title: { display: false },
    },
    scales: {
      x: {
        ticks: { font: { size: 8 } },
        grid: { display: false },
      },
      y: {
        ticks: { stepSize: 5 },
        beginAtZero: true,
        grid: { display: true },
        borderColor: "transparent",
      },
    },
  };

  return <Bar data={data} options={options} style={{ width: "100%" }} className="bar-chart" />;
};

export default BarChart;
