import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Loadingdata from "../loadingdata";
import NotFound from "../NoDataFound";
import "./pagination.scss";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { MdOutlineArrowForwardIos } from "react-icons/md";

const PaginationCompo = ({
  Parent = ({ children }) => <>{children}</>,
  header,
  populate,
  dotRange = 5,
  limitArray = [5, 10, 20],
  asyncThunk,
  fetchInitialData = true,
  reducer,
  dataKey,
  action,
  Card,
  componentProps,
  NotFoundImage,
  NoDataHeading,
  NoDataTitle,
  apiParams
}) => {
  // console.log(currentPage, totalPages, dotRange)
  const dispatch = useDispatch();
  console.log("dataKey11", dataKey);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [initialRender, setInitialRender] = useState(false);
  const { totalPages, limit, page, results, totalResults } = useSelector(
    (state) => state[reducer]?.[dataKey]
  );
  // const totalPages = 100
  const serverParams = useSelector(
    (state) => state[reducer].paramsForThunk[action]
  );
  const loading = useSelector((state) => state[reducer]?.loadings[action]);

  // const totalPages = 100
  const [currentPage, setCurrentPage] = useState(page || 1);

  // Pagination Functions
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleLimitChange = (value) => {
    setCurrentPage(1);
    setRowPerPage(value);
  };

  const handleClickPage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPagination = () => {
    const pages = [];
    const startPage = Math.max(1, currentPage - Math.floor(dotRange / 2));
    const endPage = Math.min(totalPages, startPage + dotRange - 1);

    // Add First Page button
    if (startPage > 1) {
      pages.push(
        <button
          key={1}
          onClick={() => {
            handleClickPage(1);
          }}
        >
          1
        </button>
      );
      if (startPage > 2) {
        pages.push(<span>...</span>);
      }
    }

    // Add pages within the range
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          onClick={() => {
            handleClickPage(i);
          }}
          className={currentPage === i ? "active" : ""}
        >
          {i}
        </button>
      );
    }

    // Add Last Page button
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pages.push(
          <button disabled style={{ cursor: "default" }}>
            ...
          </button>
        );
      }
      pages.push(
        <button
          key={totalPages}
          onClick={() => {
            handleClickPage(totalPages);
          }}
        >
          {totalPages}
        </button>
      );
    }

    return pages;
  };
  // Pagination Logic end
  useEffect(() => {
    if (fetchInitialData || initialRender) {
      const newParams = {
        page: currentPage,
        limit: rowPerPage,
      };
      dispatch(
        asyncThunk({
          ...(serverParams),
          ...newParams,
          ...(apiParams || {}),
        })
      );
    } else {
      setInitialRender(true);
    }
  }, [currentPage, rowPerPage]);

  useEffect(() => {
    if (page) {
      setCurrentPage(page);
    }
    if (limit) {
      setRowPerPage(limit);
    }
  }, [page, limit]);

  // if (totalPages <= 0) return null
  return (
    <>
      {loading ? (
        <Loadingdata />
      ) : (
        <>
          {!results?.length ? (
            <NotFound
              dataKey={dataKey}
              NotFoundImage={NotFoundImage}
              NoDataHeading={NoDataHeading}
              NoDataTitle={NoDataTitle}
             
            />
          ) : (
            <Parent>
              {header}
              {results?.map((data, index) => {
                return (
                  <>
                    <Card data={data} index={index} {...componentProps} />
                  </>
                );
              })}
            </Parent>
          )}
        </>
      )}

      {totalPages > 0 && results?.length > 0 && (
        <div className="SetData-Btn">
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <span className="span-14">Rows per page:</span>

            <div className="">
              <select
                className="div-39"
                value={limit}
                onChange={(e) => handleLimitChange(e.target.value)}
              >
                {limitArray.map((limit) => (
                  <option key={limit} value={limit}>
                    {limit}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <p className="span-15">
            {currentPage} - {totalPages} of {totalResults}
          </p>
          {/* {renderPagination()} */}

          <button disabled={currentPage <= 1} onClick={handlePreviousPage}>
            <MdOutlineArrowBackIos />
          </button>
          <button disabled={currentPage >= totalPages} onClick={handleNextPage}>
            <MdOutlineArrowForwardIos />
          </button>
        </div>
      )}
    </>
  );
};

export default PaginationCompo;
