import React from "react";

function ConsoleTabs() {
  return (
    <div className="container-fluid">
      <div className="tabs-main-wrapper">
        <div className="links">
          <ul>
          <li>
              <a href="#" className="active">Quick Access</a>
            </li>
            <li>
              <a href="#">Highlights</a>
            </li>

            <li>
              <a href="#">Inbox</a>
            </li>

            <li>
              <a href="#">Directory</a>
            </li>

            <li>
              <a href="#">Guide</a>
            </li>

          </ul>
        </div>
      </div>
    </div>
  );
}

export default ConsoleTabs;
