import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
    createStudentsOfGuardianAsyncThunk,
    getGuardiansOfStudentAsyncThunk,
    getUsersAsyncThunk,
} from "../../redux/pagesSlices/userSlice.js";
import { handleModel } from "../../redux/layoutSlices/modelSlice.js";
import { toast } from "react-toastify";
import CustomButton from "../customComponents/CustomButton.jsx";
import { getClassessAsyncThunk, getStaffClassessAsyncThunk } from "../../redux/pagesSlices/classesSlice.js";
import { getOrganization } from "../../constants.js";
import { parseUrlFromModel } from "../../helpers/asset.js";
import { ApiRequests } from "../../service/ApiRequests.js";
import { setClass } from "../../redux/pagesSlices/authSlice.js";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import PaginationCompo from "../pagination/PaginationCompo.js";
import Filterinput from "../Filterinput.jsx";



const ClassModal = () => {
    const d = useDispatch();
    const [search, setSearch] = useState();
    const state = useSelector((state) => state.model?.modelState?.ClassModal);
    const modelArgs = useSelector(
        (state) => state.model?.modelArgs?.ClassModal
    );
    const args = modelArgs?.args;
    const resource = modelArgs?.resource;
    const classes = useSelector((s) => s.classes.Classes);
    const selectedSpace = useSelector((s) => s.auth.class);
    const [selectedClass, setSelectedClass] = useState(null);
    const closeModel = () => {
        d(handleModel({ model: "ClassModal", state: false }));
    };

    const handleSelectClass = (item) => {
        d(setClass(item));
        setSelectedClass(item);
        closeModel();
    };

    useEffect(() => {
        if (!selectedSpace && classes.results && classes.results.length > 0) {
            setSelectedClass(classes.results[0]);
            d(setClass(classes.results[0]));

        }
    }, [classes.results, d, selectedSpace]);

    useEffect(() => {
        // if (search) {
        const key = setTimeout(() => {
            d(getClassessAsyncThunk({
                limit: 10,
                page: 1,
                ...(search?{name: search}: {}),
            }));
        }, 1000);
        return () => clearTimeout(key);
        // }
    }, [search, d])


    const ClassesListView = ({ data: item, handleSelectClass }) => {
        return (
            <div
                className="assets-table-body">
                <div className="row">
                    <div
                        className="col-lg-6">
                        <div className="table-row gap-2">
                            <div
                                className="table-data" onClick={() => handleSelectClass(item)}>
                                <img
                                    src={
                                        parseUrlFromModel(item?.image) ||
                                        require("../../images/logo.png")
                                    }
                                    alt="#"
                                />
                                <h3>{item.name}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="people-space-modal">
            {" "}
            <Modal
                size="md"
                show={state}
                onHide={closeModel}
                aria-labelledby="example-modal-sizes-title-lg"
                className="space-listing-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Space
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <div className="link-student-filter gap-4">
                            <Filterinput
                                handleSearch={(e) => setSearch(e.target.value)}
                                placeholder="Filter space"
                                widthStyle='true'
                            />
                        </div>
                    </div>
                    <div className="main-screen-content-wrapper" style={{ height: "auto", overflow: "auto", padding: "0" }}>
                        <div className="people-body">
                            <div className="people-content">
                                <div className="container-fluid">
                                    <div className="people-header">
                                        <h1 style={{ fontSize: "14px" }}>Name</h1>
                                    </div>
                                    {/* <div className="row"> */}
                                    <PaginationCompo
                                        asyncThunk={getClassessAsyncThunk}
                                        fetchInitialData={true}
                                        componentProps={{ handleSelectClass }}
                                        children={
                                            <div className="assets-table-head">
                                                <div className="row">
                                                    <div className="col-lg-8">
                                                        <div className="table-row">
                                                            <div className="table-data gap-2">
                                                                <p>Name</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        reducer={"classes"}
                                        dataKey={"Classes"}
                                        limitArray={[10, 20, 30, 50]}
                                        action={"getClassessAsyncThunk"}
                                        Card={ClassesListView}
                                    />
                                    {/* </div> */}
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </div>
        // <div className='class-modal-container-section'>
        //     <div className='class-modal-container'>
        //     <div className="class-modal-header">
        //         <span>space</span>
        //         <span><RxCross2 /></span>
        //     </div>
        //     <div className="class-modal-content">
        //         <div className="row">
        //             <div className="col-md-12">
        //                 <div className="filter-div">
        //                 <MdOutlineFilterAlt />
        //                 <input type="text" placeholder='Filter spaces' />
        //                 </div>
        //             </div>
        //         </div>
        //         <h1>Name</h1>
        //         <div className='class-content-section'>
        //         <div className="class-modal-div">
        //             <div className='class-modal-img'>
        //                 <img src={classimg} alt="img" />
        //             </div>
        //             <p>Alpha Ilmiya</p>

        //         </div>
        //         <div className="class-modal-div">
        //             <div className='class-modal-img'>
        //                 <img src={classimg} alt="img" />
        //             </div>
        //             <p>Alpha Ilmiya</p>

        //         </div>
        //         <div className="class-modal-div">
        //             <div className='class-modal-img'>
        //                 <img src={classimg} alt="img" />
        //             </div>
        //             <p>Alpha Ilmiya</p>

        //         </div>
        //         <div className="class-modal-div">
        //             <div className='class-modal-img'>
        //                 <img src={classimg} alt="img" />
        //             </div>
        //             <p>Alpha Ilmiya</p>

        //         </div>

        //         </div>
        //     </div>

        //     <div className="SetData-Btn">
        //       <div style={{ display: "flex", justifyContent: "flex-end" }}>
        //         <span className="span-14">Rows per page:</span>
        //         <div className="">
        //           <select
        //             className="div-39"
        //           >

        //               <option>
        //                 1
        //               </option>
        //               <option>
        //                 2
        //               </option>
        //               <option>
        //                 3
        //               </option>

        //           </select>
        //         </div>
        //       </div>
        //       <p className="span-15">
        //         1-1 of 7
        //       </p>
        //       {/* {renderPagination()} */}

        //       <button>
        //         <MdOutlineArrowBackIos />
        //       </button>
        //       <button>
        //         <MdOutlineArrowForwardIos />
        //       </button>
        //     </div>
        //     </div>  
        // </div>
    );
};

export default ClassModal;
