import React, { useEffect, useState } from 'react'
import imglog1 from '../images/person 1.svg'
import imglog2 from '../images/person 2.svg'
import imglog3 from '../images/person 3.svg'
import imglog4 from '../images/person 4.svg'
import imglog5 from '../images/open_in_new_24dp_5F6368_FILL0_wght400_GRAD0_opsz24 2.svg'
import SearchBar from './SearchBar'
import { getStaffsAsyncThunk } from '../redux/pagesSlices/userSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import PaginationCompo from '../components/pagination/PaginationCompo'
import StaffImg from '../images/staff-icon.svg'
import Filterinput from '../components/Filterinput'

const ConsolTable2 = () => {
    const [searchValue, setSearchValue] = useState("");
    const [selected, setSelected] = useState('list' || localStorage.getItem("tabVal"));
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const labels = useSelector((s) => s?.user?.labels) ?? {};
    const members = useSelector((s) => s?.user?.staffs);
    const navigate = useNavigate();
    const [studentcolumns, setStudentColumns] = useState([
        { key: "username", label: "Name", isSortable: true, order: "asc" },
        { key: "email", label: "Email" },
        { key: "Action", label: "Action" },
    ]);
    const d = useDispatch();
    // useEffect(() => {
    //     if (members?.results?.length) {
    //         d(getLabelsAsyncThunk());
    //     }
    // }, [d, members, members?.results?.length]);

    const [searchParams] = useSearchParams();
    const tab = searchParams.get("tab");

    const toggleUserSelection = (userId) => {
        if (selectedUsers.includes(userId)) {
            setSelectedUsers(selectedUsers?.filter((id) => id !== userId));
        } else {
            setSelectedUsers([...selectedUsers, userId]);
        }
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            let params = {
                roles: ["Admin", "Teacher", "Editor"],
                page: 1,
                limit: 10,
            };
            if (searchValue) {
                const values = searchValue
                    .trim()
                    .split(",")
                    .map((value) => value.toLowerCase().trim());
                if (values.length > 1) {
                    if (searchValue.includes("label:")) {
                        const item = values.find((item) => item.includes("label:"));
                        if (item) {
                            params.label = item.split(":")[1].trim();
                        }
                    }
                    if (searchValue.includes("staff:")) {
                        const item = values.find((item) => item.includes("staff:"));
                        if (item) {
                            params.username = item.split(":")[1].trim();
                        }
                    }
                } else {
                    params.username = searchValue.trim();
                }
            }
            d(getStaffsAsyncThunk({ ...params, page: 1 }));
        }, 100);
        return () => clearTimeout(timer);
    }, [searchValue, d, studentcolumns]);

    if (selected === "list") {
        localStorage.setItem("tabVal", "list");
    } else {
        localStorage.setItem("tabVal", "grid");
    }

    useEffect(() => {
        setSelectedUsers([]);
        setIsCheckboxChecked(false);
    }, [tab]);

    const ListCard = ({ data }) => {
        return (
            <tr>
                <td>
                    <div className='infoa'>
                        <div className="infoimg">
                            <img src={data?.photoURL || require("../images/fav.png")}
                                alt="#" className='tableimg' />
                        </div>
                        <span>{data?.username || data?.displayName}</span>
                    </div>
                </td>
                <td>
                    <div className="info2">
                        <span>{data?.email}</span>
                    </div>
                </td>
                {/* <td>
                    <div className="info2">
                        <img src={imglog5} alt="open_in_new_24dp_5F6368_FILL0_wght400_GRAD0_opsz24 2.svg" className='icon' />
                    </div>
                </td> */}
            </tr>
        )
    }

    return (
        <div className='consol-table-page'>
            <div className="consol-card-heading mb-3">
                <h2>Staff</h2>
            </div>
            {/* <SearchBar text='Not read' /> */}
            <div className="container-fluid">
                <div className="link-student-filter gap-4">
                    <Filterinput
                        handleSearch={(e) => setSearchValue(e.target.value)}
                        placeholder="Filter Staff"
                        // widthStyle='true'
                    />
                </div>
            </div>
            <div className='consol-table'>
                <PaginationCompo
                    asyncThunk={getStaffsAsyncThunk}
                    fetchInitialData={false}
                    reducer={"user"}
                    nodataTitle={"No staff found"}
                    imgTitle={StaffImg}
                    dataKey={"staffs"}
                    limitArray={[10, 20, 30, 50]}
                    action={"getStaffsAsyncThunk"}
                    Card={ListCard}
                    // Card={ListCard}
                    componentProps={{
                        selectedUsers,
                        toggleUserSelection,
                        roles: ["Admin", "Teacher", "Editor"],
                    }}
                    Parent={({ children }) => {
                        return selected === "list" ? (
                            <table className='table2-of-consol'>
                                <thead>
                                    <tr>
                                        <th>
                                            <div className="info">
                                                <span>Name</span>
                                            </div>
                                        </th>
                                        <th>
                                            <div className="info1 info-space">
                                                <span>Email</span>
                                            </div>
                                        </th>
                                        
                                        {/* <th>
                                            <div className="info">
                                                <span>View</span>
                                            </div>
                                        </th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {children}
                                </tbody>
                            </table>
                        ) : (
                            // </div>
                            <>{children}</>
                        );
                    }}
                />
                {/* <table className='table2-of-consol'>
                    <thead>
                        <tr>
                            <th>
                                <div className="info">
                                    <span>Name</span>
                                </div>
                            </th>
                            <th>
                                <div className="info1">
                                    <span>Grade</span>
                                </div>
                            </th>
                            <th>
                                <div className="info">
                                    <span>View</span>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div className='infoa'>
                                    <div className="infoimg">
                                        <img src={imglog1} alt="" className='tableimg' />
                                    </div>
                                    <span>You got it 30 bronze</span>
                                </div>
                            </td>
                            <td>
                                <div className="info2">
                                    <span>6</span>
                                </div>
                            </td>
                            <td>
                                <div className="info2">
                                    <img src={imglog5} alt="open_in_new_24dp_5F6368_FILL0_wght400_GRAD0_opsz24 2.svg" className='icon' />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table> */}
            </div>
        </div>
    )
}

export default ConsolTable2
