import React, { useEffect, useState } from 'react'
import people from '../assets/images/icons/people.svg'
import guardian from '../assets/images/icons/Guardians.svg'
import clock from '../assets/images/icons/clock-loader.svg'
import circle from '../assets/images/icons/console-circle.svg'
import { useSelector } from 'react-redux'
import { get, onChildAdded, ref } from 'firebase/database'
import { db } from '../config/firebase'
import { organization, redirect_uris } from '../constants'
import { ApiRequests } from '../service/ApiRequests'

function ConsoleHeader() {
   const [data, setData] = useState([]);
   const [users, setUsers] = useState();
   const [loading, setLoading] = useState(true);
   const customToken = localStorage.getItem("custom-token");
   const [selectionRange, setSelectionRange] = useState({
      key: "selection",
   });
   const getData = async () => {
      try {
         setLoading(true);
         const res = await ApiRequests.getOverview({ ...selectionRange });
         setData(res.data);
      } catch (error) {
         console.log(error);
      } finally {
         setLoading(false);
      }
   };

   useEffect(() => {
      getData();
   }, [selectionRange]);
   const org = organization();
   const getLoggedInUsers = async () => {
      const allUsersRef = ref(db, `stream/${org.tenantId}`);

      // Fetch initial data (snapshot)
      const snapshot = await get(allUsersRef);
      if (snapshot.exists()) {
         const initialUsers = snapshot.val();
         setUsers(Object.values(initialUsers)); // Convert object to an array
      }

      // Set up real-time listener for new users
      onChildAdded(allUsersRef, (data) => {
         const newUser = data.val();
         setUsers((prevUsers) => {
            if (!prevUsers.find((user) => user.id === newUser.id)) {
               return [...prevUsers, newUser];
            }
            return prevUsers; // Avoid duplicates
         });
      });
   };

   useEffect(() => {
      getLoggedInUsers();
   }, [org.tenantId]);



   return (
      <div className='console-header-icons'>
         <a style={{ color: "black" }} href={redirect_uris.People + `?token=${customToken}?type=report`}
            target="blank">
            <div className="icon1">
               <img src={clock} alt="image" />
               <span>{data?.totalStudents || 0}</span>
            </div>
         </a>
         <a style={{ color: "black" }} href={redirect_uris.People + `guardians?token=${customToken}?type=report`}
            target="blank">
            <div className="icon1">
               <img src={guardian} alt="image" className='guardian' />
               <span>{data?.totalGuardian || 0}</span>
            </div>
         </a>
         <a style={{ color: "black" }} href={redirect_uris.People + `family?token=${customToken}?type=report`}
            target='blank'>
            <div className="icon1">
               <img src={people} alt="image" />
               <span>{data?.totalFamily || 0}</span>
            </div>
         </a>
         <div className="icon2">
            <img src={circle} alt="image" />
            <span>{users ? users.filter(user => user.status === "online").length : 0}</span>
         </div>
      </div>
   )
}

export default ConsoleHeader
