import React from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { MdOutlineHelpCenter } from "react-icons/md";
import { CgFileDocument } from "react-icons/cg";
import { FaGraduationCap } from "react-icons/fa6";
import coinImg from '../assets/images/icons/coin-img.svg'
import news from '../assets/images/icons/news.svg'
import thumbsUp from '../assets/images/icons/thumbs-up.svg'
import { GoTrophy } from "react-icons/go";


function QuestionColumn({ data, totalTrophies }) {
  return (
    <div>
      <div className="question-coloumn">
        <Row>
          <div className="col-md-4 mb-2">
            <div className="card-div mb-0">
              <div className="ques">
                <div className="user">
                  {/* <CgFileDocument /> */}
                  <img src={news} alt="" />
                </div>
                <div>
                  <h4>{data?.totalCourses || 0}</h4>
                  <p>Total Lessons</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-2">
            <div className="card-div mb-0">
              <div className="ques">
                <div className="user">
                  {/* <MdOutlineHelpCenter style={{ color: "#5F6368" }} /> */}
                  {/* <img src={thumbsUp} alt="" /> */}
                  <GoTrophy style={{ color: "#2181FA" }} />

                </div>
                <div>
                  <h4>{totalTrophies}</h4>
                  <p>Total Trophies</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-2">
            <div className="card-div mb-0">
              <div className="ques">
                <div className="user">
                  {/* <FaGraduationCap style={{ color: "#45413C" }} /> */}
                  <img src={coinImg} alt="" />
                </div>
                <div>
                  <h4>{data?.totalMarks}</h4>
                  <p>Total Points</p>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </div>
    </div>
  );
}

export default QuestionColumn;
